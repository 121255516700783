// ensure the stylesheets are included in the compiled pack
require.context('../stylesheets/', true, /^\.\/[^_].*\.(css|scss|sass)$/i);
// ensure images are included in the compiled pack
require.context('../images/', true);
// ensure external frontend is included in pack
require.context('dvla-external-frontend/dist/media', true)

// initialise javascript
import {
  GOVUKInitAll,
  HMRCInitAll,
  ClearCookies,
} from 'dvla-external-frontend';
GOVUKInitAll();
HMRCInitAll();
ClearCookies.init();

// add form util
import formUtils from '../components/js-form-utils';

window.onload = () => {
  formUtils.registerInputListeners();
};
